import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityService } from "../_services";
import moment from "moment";
import { getEnvironmentURL } from "../_helpers";
import ModalGeneric from "../_components/generic/ModalGeneric";
import { useDispatch, useSelector } from "react-redux";
import { utilityActions } from "../_actions";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function RiNhtsaIVRPage(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [vins, setVins] = useState(
    "1N4BL4CW6PN314522\n\
    1C6SRFLT9KN836247\n\
    KL4CJ1SB1JB521002\n\
    3GCPYFED6MG359800\n\
    3GCUDHEL6PG364954\n\
    1C6SRFFT3LN279185\n\
    1C6RR7TT5FS783574\n\
    1FMCU9HD6KUB38954\n\
    1GT49SEY8NF235316\n\
    3GKALTEV8ML400483\n\
    5TFCZ5ANXMX278721\n\
    1FTEW1E52JKE09797\n\
    3GTUUCED2NG502398\n\
    1GTU9FEL4MZ281783\n\
    3GTUUGED3NG672650\n\
    1FMCU9H61LUA02136\n\
    1GKS2JKL8PR323254\n\
    5TFUY5F18HX639169\n\
    1FTFW1E56NKD14217\n\
    5XYP3DHC5NG223857\n\
    1GTUUEET3NZ516989\n\
    1C6SRFLT8KN843528\n\
    1C6SRFMT6KN906981\n\
    1G4PP5SK1F4125091\n\
    3GCUYEED7NG218572\n\
    1GT49REY1RF149344\n\
    3GNAXWEG7PS174506\n\
    3GCPDCED1NG552013\n\
    3GNKBHRS1NS188332\n\
    1FTFW1E83NKD13424"
  );

  // const [vins, setVins] = useState("5N1AT2MV1JC740830");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [openIvrModal, setOpenIvrModal] = useState(false);
  const [editIvrId, setEditIvrId] = useState(null);
  const [editIvrVin, setEditIvrVin] = useState(null);

  const [changeEntryPhoto, setChangeEntryPhoto] = useState("");
  const [changeConformanceUser, setChangeConformanceUser] = useState("");
  const [changeConformanceAction, setChangeConformanceAction] = useState("");
  const [changeConformanceDate, setChangeConformanceDate] = useState(null);
  const [changePhysicalPossession, setChangePhysicalPossession] =
    useState(null);
  const [changeExitPhotoDate, setChangeExitPhotoDate] = useState(null);

  const vehicleImageTypes = useSelector(
    (state) => state.utility.vehicle_image_types
  );
  const users = useSelector((state) => state.utility.web_users);

  useEffect(() => {
    dispatch(
      utilityActions.getUtility("vehicle_image_types", {
        perPage: 1000,
        orders: [
          {
            field: "display_order",
            direction: "asc",
          },
        ],
      })
    );
    dispatch(
      utilityActions.getUtility("web_users", {
        perPage: 1000,
        orders: [
          {
            field: "lastname",
            direction: "asc",
          },
        ],
        filters: [
          {
            field: "active",
            operator: "eq",
            value: 0,
          },
          {
            field: "roleid",
            operator: "eq",
            value: 2,
          },
        ],
      })
    );
  }, [dispatch]);

  const sendVins = () => {
    setLoading(true);
    setSubmitted(true);
    let vins2 = vins.replace(/\n/g, ",");
    vins2 = vins2.replace(/ /g, "");

    utilityService
      .getUtility("vehicles_base", {
        relations: ["images", "client_load_base", "vehicle_conformance_plus"],
        appends: ["nhtsa_conformance_location"],
        filters: [
          { field: "vehiclevin", operator: "in", value: vins2 },
          { field: "cancelled", operator: "eq", value: 0 },
        ],
        perPage: 1000,
        // orders: [{ field: "vehicleid", direction: "desc" }],
      })
      .then((response) => {
        setResults(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateIvr = () => {
    utilityService
      .getEndpoint(`api/reports/management/ivr/${editIvrId}`, "PUT", {
        body: {
          changeEntryPhoto,
          changeConformanceUser,
          changeConformanceAction,
          changeConformanceDate,
          changePhysicalPossession,
          changeExitPhotoDate,
        },
      })
      .then((response) => {
        console.log({ response });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const editIvr = (vehicleId, vehicleVin) => {
    setEditIvrId(vehicleId);
    setEditIvrVin(vehicleVin);
    setOpenIvrModal(true);
  };

  return (
    <PageWithAppDrawer2 pageTitle={"RI NHTSA IVR Generator"}>
      {loading && (
        <Paper className={classes.paper}>
          <Grid container spacing={1}>
            <CircularProgress />
          </Grid>
        </Paper>
      )}
      {!submitted && (
        <Paper className={classes.paper}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">IVR VIN List</Typography>
              <TextField
                value={vins}
                fullWidth
                multiline
                rows={5}
                variant="outlined"
                onChange={(e) => setVins(e.target.value)}
              />
              <Button variant="contained" color="primary" onClick={sendVins}>
                Get IVRs
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
      {submitted && !loading && results.length > 0 && (
        <IVRList results={results} editIvr={editIvr} />
      )}
      <ModalGeneric
        open={openIvrModal}
        title={"Update IVR"}
        closeText={"Do Update"}
        onClose={() => {
          setOpenIvrModal(false);
          updateIvr();
        }}
        cancelText={"Cancel"}
        onCancel={() => {
          setOpenIvrModal(false);
        }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <a
            href={`http://mmsc400.manheim.com/MABEL/ECR2I.htm?CLIENT=SIMUC&CONFIGID=BUYER&SAUCI=DETA&VIN=${editIvrVin}`}
            target="_blank"
          >
            Manheim CR
          </a>
          <FormControl fullWidth>
            <InputLabel id="select-change-entry-photo">
              Change Entry Photo
            </InputLabel>
            <Select
              fullWidth
              labelId="select-change-entry-photo"
              id="change-entry-photo"
              value={changeEntryPhoto}
              onChange={(e) => {
                setChangeEntryPhoto(e.target.value);
              }}
            >
              <MenuItem value={""}>Don't Change</MenuItem>
              {vehicleImageTypes?.items &&
                vehicleImageTypes.items.map((iType) => (
                  <MenuItem key={iType.id} value={iType.id}>
                    {iType.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <KeyboardDatePicker
              margin="normal"
              name="exit-phto-date"
              label={"Exit Photo Date"}
              format="MM/dd/yyyy"
              value={changeExitPhotoDate}
              onChange={(date, value) => setChangeExitPhotoDate(value)}
            />
          </FormControl>
          <FormControl fullWidth>
            <KeyboardDatePicker
              margin="normal"
              name="physical-posession"
              label={"Physical Possession Date"}
              format="MM/dd/yyyy"
              value={changePhysicalPossession}
              onChange={(date, value) => setChangePhysicalPossession(value)}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="select-change-conformance-user">
              Change Conformance User
            </InputLabel>
            <Select
              fullWidth
              labelId="select-change-conformance-user"
              id="change-conformance-user"
              value={changeConformanceUser}
              onChange={(e) => {
                setChangeConformanceUser(e.target.value);
              }}
            >
              <MenuItem value={""}>Don't Change</MenuItem>
              {users?.items &&
                users.items.map((user) => (
                  <MenuItem key={user.userid} value={user.userid}>
                    {user.lastname} {user.firstname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <KeyboardDatePicker
              margin="normal"
              name="conformance-date"
              label={"Conformance Date"}
              format="MM/dd/yyyy"
              value={changeConformanceDate}
              onChange={(date, value) => setChangeConformanceDate(value)}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="select-change-conformance-action">
              FMVSS 101 Action
            </InputLabel>
            <Select
              fullWidth
              labelId="select-change-conformance-action"
              id="change-conformance-action"
              value={changeConformanceAction}
              onChange={(e) => {
                setChangeConformanceAction(e.target.value);
              }}
            >
              <MenuItem value={""}>Don't Change</MenuItem>
              <MenuItem value={0}>Inspected, no actions required</MenuItem>
              <MenuItem value={1}>Inspected, previously conformed</MenuItem>
              <MenuItem value={2}>Inspected, conformed by RI</MenuItem>
            </Select>
          </FormControl>
        </MuiPickersUtilsProvider>
      </ModalGeneric>
    </PageWithAppDrawer2>
  );
}

function IVRList(props) {
  const results = props.results;

  return (
    <Grid container spacing={1}>
      {results.map((item) => {
        return (
          <Grid item key={item.vehicleid}>
            <IVRItem item={item} editIvr={props.editIvr} />
          </Grid>
        );
      })}
    </Grid>
  );
}

function IVRItem(props) {
  const dispatch = useDispatch();
  const item = props.item;
  const [riLabel, setRiLabel] = useState(null);
  const [exitPhoto, setExitPhoto] = useState(null);
  const [dotLink, setDotLink] = useState(null);
  const [nhtsaConformanceLocation, setNhtsaConformanceLocation] = useState("");

  useEffect(() => {
    if (item) {
      setRiLabel(
        item.images.find(
          (item2) => item2.type?.name === "RI Certification Label"
        )
      );
      setExitPhoto(
        item.images.find((item2) => item2.type?.name.includes("Exit Photo"))
      );
      setDotLink(
        `${getEnvironmentURL()}/api/vehicles/${
          item.vehicleid
        }/multipleForms/0?docs=15,17,16,25,35,36,24,9&download&downloadName=${
          item.vehiclevin
        }-all.pdf`
      );
      setNhtsaConformanceLocation(`${item.nhtsa_conformance_location.name}, 
        ${item.nhtsa_conformance_location.street},
        ${item.nhtsa_conformance_location.city},
        ${item.nhtsa_conformance_location.state},
        ${item.nhtsa_conformance_location.zip}`);
    }
  }, [item]);

  const getPhysicalReleaseDate = (item) => {
    const bondDate = item.bondreleasedate ? moment(item.bondreleasedate) : null;
    const exitDate = exitPhoto ? moment(exitPhoto.dateuploaded) : null;
    const vaultDate = item.vaultreleasedate
      ? moment(item.vaultreleasedate)
      : null;
    let releaseDate = null;
    if (!releaseDate && vaultDate) releaseDate = vaultDate;
    if (!releaseDate && exitDate) releaseDate = exitDate;
    if (!releaseDate && bondDate) releaseDate = bondDate;

    if (bondDate && bondDate.isBefore(releaseDate)) releaseDate = bondDate;
    if (exitDate && exitDate.isBefore(releaseDate)) releaseDate = exitDate;
    if (vaultDate && vaultDate.isBefore(releaseDate)) releaseDate = vaultDate;

    if (!releaseDate) {
      return "----------";
    }
    return releaseDate.add(2, "days").format("YYYY-MM-DD");
  };

  const isReleased = (item) => {
    const today = moment();
    const bondDate = item.bondreleasedate ? moment(item.bondreleasedate) : null;
    const vaultDate = item.vaultreleasedate
      ? moment(item.vaultreleasedate)
      : null;

    // console.log({
    //   vin: item.vehiclevin,
    //   today: today.format("YYYY-MM-DD"),
    //   bondDate: bondDate?.format("YYYY-MM-DD"),
    //   vaultDate: vaultDate?.format("YYYY-MM-DD"),
    // });

    if (bondDate) {
      // console.log(item.vehiclevin + " bondReleased", {bondDate})
      return true;
    }
    if (vaultDate && vaultDate.isBefore(today)) {
      // console.log(item.vehiclevin + " vaultReleased", {vaultDate, today})
      return true;
    }

    // console.log(item.vehiclevin + " notReleased", {vaultDate, bondDate, today})
    return false;
  };

  const deletePhoto = (photoId) => {
    dispatch(
      utilityActions.deleteOneUtility("vehicle_images", photoId, null, () => {
        alert(`Photo ${photoId} deleted`);
      })
    );
  };

  return (
    // <Paper className={classes.paper}>
    <Grid container>
      <Grid item xs={4}>
        VIN
      </Grid>
      <Grid item xs={8}>
        <RouterLink to={`/vehicles/${item.vehicleid}`} target="_blank">
          {item.vehiclevin}
        </RouterLink>{" "}
        ({item.vehicleid}){" "}
        {/* <Button
          size="small"
          color="primary"
          onClick={() => {
            props.editIvr(item.vehicleid, item.vehiclevin);
          }}
          // style={{ color: "white" }}
        >
          View Details
        </Button>
        <Button
          size="small"
          color="primary"
          href={`/#/photoupload/${item.vehicleid}`}
          target="_blank"
          // style={{ color: "white" }}
        >
          Photos
        </Button> */}
      </Grid>

      <Grid item xs={12}>
        <Typography style={{ fontWeight: "bold" }}>Dates</Typography>
      </Grid>

      <Grid item xs={4} style={{ backgroundColor: "#eee" }}>
        Contract
      </Grid>
      <Grid item xs={8} style={{ backgroundColor: "#eee" }}>
        {item.recordcreateddate
          ? moment(item.recordcreateddate).format("YYYY-MM-DD")
          : "N/A"}
      </Grid>

      <Grid item xs={4}>
        Imported
      </Grid>
      <Grid item xs={8}>
        {item.crossedborderdate
          ? moment(item.crossedborderdate).format("YYYY-MM-DD")
          : "N/A"}
      </Grid>

      <Grid item xs={4} style={{ backgroundColor: "#eee" }}>
        Physical Posession
      </Grid>
      <Grid item xs={8} style={{ backgroundColor: "#eee" }}>
        {item.confirmedladeddate
          ? moment(item.confirmedladeddate).format("YYYY-MM-DD")
          : "N/A"}
      </Grid>
      <Grid item xs={4}>
        Conformance
      </Grid>
      <Grid item xs={8}>
        {item.dot_inspection_date
          ? moment(item.dot_inspection_date).format("YYYY-MM-DD")
          : "N/A"}
      </Grid>

      <Grid item xs={4} style={{ backgroundColor: "#eee" }}>
        Certification Label
      </Grid>
      <Grid item xs={8} style={{ backgroundColor: "#eee" }}>
        {riLabel ? moment(riLabel.dateuploaded).format("YYYY-MM-DD") : "N/A"}
      </Grid>

      <Grid item xs={4}>
        DOT Submitted
      </Grid>
      <Grid item xs={8}>
        {item.submitteddotdate
          ? moment(item.submitteddotdate).format("YYYY-MM-DD")
          : "N/A"}
      </Grid>

      <Grid item xs={4} style={{ backgroundColor: "#eee" }}>
        Title
      </Grid>
      <Grid item xs={8} style={{ backgroundColor: "#eee" }}>
        {item.titlesenttobmvdate
          ? moment(item.titlesenttobmvdate).format("YYYY-MM-DD")
          : "N/A"}
      </Grid>

      <Grid item xs={4}>
        Bond Release
      </Grid>
      <Grid item xs={8}>
        {item.bondreleasedate
          ? moment(item.bondreleasedate).format("YYYY-MM-DD")
          : "N/A"}
      </Grid>

      <Grid item xs={4} style={{ backgroundColor: "#eee" }}>
        Vault
      </Grid>
      <Grid item xs={8} style={{ backgroundColor: "#eee" }}>
        {item.vaultreleasedate
          ? moment(item.vaultreleasedate).format("YYYY-MM-DD")
          : "N/A"}
      </Grid>
      <Grid item xs={4}>
        Physical Release / Scheduled Release
      </Grid>
      <Grid item xs={8}>
        {getPhysicalReleaseDate(item)}
      </Grid>

      <Grid item xs={12}>
        <Typography style={{ fontWeight: "bold" }}>Verified Photos</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {item.images &&
            item.images.map((photo) => {
              if (Number(photo.imagetype) === 0) {
                return null;
              }
              let photoAfterDot = false;
              let photoAfterDotColor = "inherit";
              // photoAfterDotColor = "red"; // for testing only
              if (
                moment(item.submitteddotdate).diff(
                  moment(photo.dateuploaded),
                  "days"
                ) < 0
              ) {
                photoAfterDot = true;
              }

              let useRiDate = false;
              if (riLabel) {
                const photoType = photo.type?.name;
                if (photoType === "Exit Photo" || photoType === "Entry Photo") {
                  useRiDate = false;
                } else {
                  useRiDate = moment(photo.dateuploaded).isBefore(
                    riLabel.dateuploaded
                  );
                }
              }
              return (
                <Grid
                  item
                  key={photo.pwimageid}
                  style={{
                    width: 200,
                    border: "1px solid grey",
                    backgroundColor: photoAfterDot
                      ? photoAfterDotColor
                      : "inherit",
                  }}
                >
                  <b>{photo.type?.name || photo.docnote}</b>
                  {/* <Button
                    size="small"
                    color="primary"
                    onClick={() => deletePhoto(photo.id)}
                    // style={{ color: "white" }}
                  >
                    Delete
                  </Button> */}
                  <br />
                  {useRiDate
                    ? moment(riLabel.dateuploaded).format("YYYY-MM-DD")
                    : moment(photo.dateuploaded).format("YYYY-MM-DD")}
                  <br />
                  <span style={{ size: 9 }}>{nhtsaConformanceLocation}</span>
                  <br />
                  <a href={photo.cloud_url_orig} target="_blank">
                    <img
                      src={photo.cloud_url_thumb}
                      style={{ height: 100, width: 100 }}
                    />
                  </a>
                </Grid>
              );
            })}
          {!exitPhoto && (
            <Grid item>
              Exit Photo
              <Typography style={{ fontWeight: "bold" }}>
                {isReleased(item)
                  ? "See Site Contact for Exit Photo"
                  : "Vehicle is not released"}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography style={{ fontWeight: "bold" }}>Conformance</Typography>
      </Grid>

      {item.vehicle_conformance_plus &&
        item.vehicle_conformance_plus.conformance_items.map((cItem) => {
          return (
            <Fragment key={cItem.id}>
              <Grid item xs={4}>
                {cItem.description}
              </Grid>
              <Grid item xs={8}>
                Location: {nhtsaConformanceLocation}
                <br />
                Conformance: {cItem.conformity_action}
                <br />
                Completed By: {cItem.completed_by_user?.name}
                <br />
                Completed Date:{" "}
                {moment(cItem.completed_date).format("YYYY-MM-DD")}
                <br />
                Notes: {cItem.remedy_notes}
              </Grid>
            </Fragment>
          );
        })}
      {!item.vehicle_conformance_plus && (
        <Fragment>
          <Grid item xs={4}></Grid>
          <Grid item xs={8}>
            <Typography style={{ fontWeight: "bold", color: "red" }}>
              NO CONFORMANCE ITEMS
            </Typography>
          </Grid>
        </Fragment>
      )}

      <Grid item xs={12}>
        <Typography style={{ fontWeight: "bold" }}>
          Recall Information
        </Typography>
      </Grid>
      <Grid item xs={4}>
        Has Recall
      </Grid>
      <Grid item xs={8}>
        {Number(item.has_recall) ? (
          <>
            Has Outstanding Recalls, contact{" "}
            <a href="mailto:dvsri@godvs.com">DVSRI@godvs.com</a> more
            information
          </>
        ) : (
          "No Outstanding Recalls Found"
        )}
      </Grid>
      <Grid item xs={4}>
        Ford
      </Grid>
      <Grid item xs={8}>
        <a
          href="https://www.ford.com/support/recalls/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ford Recall Website
        </a>
      </Grid>
      <Grid item xs={4}>
        Toyota
      </Grid>
      <Grid item xs={8}>
        <a
          href="https://www.toyota.com/recall"
          target="_blank"
          rel="noopener noreferrer"
        >
          Toyota Recall Website
        </a>
      </Grid>
      <Grid item xs={4}>
        Nissan
      </Grid>
      <Grid item xs={8}>
        <a
          href="https://www.nissanusa.com/recalls-vin.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Nissan Recall Website
        </a>
      </Grid>
      <Grid item xs={4}>
        Chrysler, Dodge, Jeep, RAM (MOPAR)
      </Grid>
      <Grid item xs={8}>
        <a
          href="https://www.mopar.com/en-us/my-vehicle/recalls/search.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Chrysler, Dodge, Jeep, RAM (MOPAR) Recall Website
        </a>
      </Grid>
      <Grid item xs={4}>
        GM
      </Grid>
      <Grid item xs={8}>
        <a
          href="https://experience.gm.com/ownercenter/recalls"
          target="_blank"
          rel="noopener noreferrer"
        >
          GM Recall Website
        </a>
      </Grid>
      <Grid item xs={4}>
        NHTSA
      </Grid>
      <Grid item xs={8}>
        <a
          href="https://vinrcl.safercar.gov/vin/vinLookup"
          target="_blank"
          rel="noopener noreferrer"
        >
          NHTSA Recall Website
        </a>
      </Grid>
      <Grid item xs={4}>
        CARFAX
      </Grid>
      <Grid item xs={8}>
        <a
          href="https://www.carfaxonline.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          CARFAX Recall Website
        </a>{" "}
        CARFAX can only be used as a reference
        <br />
        (CARFAX CANNOT BE USED AS A RECALL STATEMENT ON DOT SUBMISSIONS)
      </Grid>

      <Grid item xs={12}>
        <Typography style={{ fontWeight: "bold" }}>Other Documents</Typography>
      </Grid>
      {dotLink && (
        <Grid item xs={12}>
          <a href={dotLink} target="_blank">
            DOT Package (PDF)
          </a>
        </Grid>
      )}
      <Grid item xs={12}>
        DVS Maintains any additional paperwork at DVS Headquarters in
        Indianapolis
        <br />
        Please contact DVS for more information:
        <br />
        <a href="tel://18773874639">1-877-387-4639</a>
        <br />
        <a href="mailto:dvsri@godvs.com">DVSRI@godvs.com</a>
      </Grid>

      <Grid item xs={12}>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      </Grid>
    </Grid>
    // </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export { RiNhtsaIVRPage };
