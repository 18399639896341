import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { utilityActions } from "../_actions";
import {
  Paper,
  Grid,
  Select,
  TextField,
  Button,
  Hidden,
  Typography,
  Backdrop,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClientNotes from "../_components/client/ClientNotes";
import ClientDefaults from "../_components/client/ClientDefaults";
import ClientOverrides from "../_components/client/ClientOverrides";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Alert from "@material-ui/lab/Alert";
import ClientGstHst from "../_components/client/ClientGstHst";

class AdminClientDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    const { id: clientId } = this.props.match ? this.props.match.params : {};
    this.state = {
      clientId,
      reload: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { id: clientId } = nextProps.match ? nextProps.match.params : {};
    if (clientId !== prevState.clientId) {
      return {
        ...prevState,
        clientId,
        reload: true,
      };
    }
    return false;
  }

  async componentDidMount() {}

  render() {
    const { clientId, reload } = this.state;
    const { classes } = this.props;

    if (reload === true) {
      this.setState({ reload: false }, () => {
        this.componentDidMount();
      });
      return <></>;
    }

    return (
      <PageWithAppDrawer2 pageTitle={`Client #${clientId}`}>
        <RouterLink to="/admin/clients">All Clients</RouterLink>
        <Paper className={classes.paper}>
          <ClientDetails itemId={clientId} />
        </Paper>
      </PageWithAppDrawer2>
    );
  }
}

function ClientDetails(props) {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.utility.clients_item);
  const auctions = useSelector((state) => state.utility.auctions);
  const states = useSelector((state) => state.utility.us_states);
  const clientTypes = useSelector((state) => state.utility.client_types);
  const paymentTypes = useSelector((state) => state.utility.payment_types);
  const clients_active = useSelector((state) => state.utility.clients_active);
  const clientGroups = useSelector((state) => state.utility.client_groups);
  const clientSocLocations = useSelector(
    (state) => state.utility.client_soc_locations
  );
  const title_statuses = useSelector((state) => state.utility.title_statuses);
  const titleApplicationTypes = useSelector(
    (state) => state.utility.title_application_types
  );

  const [tabIndex, setTabIndex] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const fields = [
    {
      width: 2,
      title: "Client Name",
      field: "clientname",
      type: "string",
    },
    {
      width: 2,
      title: "Client Group",
      field: "client_group_id",
      type: "selectclientgroup",
    },
    {
      width: 2,
      title: "Active",
      field: "clientactive",
      type: "selectclientactive",
    },
    {
      width: 2,
      title: "Titles Customer",
      field: "is_titles",
      type: "boolean",
    },
    {
      width: 2,
      title: "RI Customer",
      field: "is_ri",
      type: "boolean",
    },
    {
      width: 2,
      title: "RI SOC Address",
      field: "soc_location_id",
      type: "selectclientsoclocation",
    },
    {
      width: 2,
      title: "RI Registration Documents Complete",
      field: "ri_reg_docs_complete",
      type: "boolean",
    },
    {
      width: 2,
      title: "RI Requires Transportation",
      field: "requires_transportation",
      type: "boolean",
    },
    {
      width: 2,
      title: "DVS Rack Rates",
      field: "dvsrackrates",
      type: "boolean",
    },
    {
      width: 2,
      title: "Dealers License #",
      field: "dealerlicenseno",
      type: "string",
    },
    {
      width: 2,
      title: "Country",
      field: "clientcountrycode",
      type: "selectcountrycode",
    },
    {
      width: 1,
      title: "Street Address",
      field: "clientstreetaddress",
      type: "string",
    },
    {
      width: 2,
      title: "City",
      field: "clientcity",
      type: "string",
    },
    {
      width: 2,
      title: "State",
      field: "clientstateid",
      type: "state",
    },
    {
      width: 2,
      title: "Zip",
      field: "clientzip",
      type: "string",
    },
    {
      width: 2,
      title: "Phone",
      field: "clientphonenumber",
      type: "string",
    },
    {
      width: 2,
      title: "Fax",
      field: "clientfaxnumber",
      type: "string",
    },
    {
      width: 2,
      title: "Email",
      field: "clientemailaddress",
      type: "string",
    },
    {
      width: 2,
      title: "Invoice Email",
      field: "invoiceemailaddress",
      type: "string",
    },
    {
      width: 2,
      title: "Contact",
      field: "clientcontactname",
      type: "string",
    },
    {
      width: 2,
      title: "FID #",
      field: "fidno",
      type: "string",
    },
    {
      width: 2,
      title: "RRMC #",
      field: "rrmcno",
      type: "string",
    },
    {
      width: 2,
      title: "Exemption #",
      field: "exemptionno",
      type: "string",
    },
    {
      width: 2,
      title: "Type",
      field: "clienttypeid",
      type: "selectclienttype",
    },
    {
      width: 2,
      title: "Bulk Invoicing",
      field: "clientsingleinvoice",
      type: "selectsingleinvoice",
    },
    {
      width: 2,
      title: "Invoice Breakdown",
      field: "clientinvoicedetails",
      type: "boolean",
    },
    {
      width: 2,
      title: "Payment Type",
      field: "paymenttype",
      type: "selectpaymenttype",
    },
    {
      width: 2,
      title: "Special Mailing Address",
      field: "clientspecialmailing",
      type: "selectspecialmailing",
    },
    {
      width: 2,
      title: "Client Legal Name",
      field: "legal_name",
      type: "string",
    },
    {
      width: 2,
      title: "Client Legal Address (Full)",
      field: "legal_address",
      type: "string",
    },
    {
      width: 2,
      title: "ODO Import Purchasing Client",
      field: "client_id_odo_purchase",
      type: "selectclient",
    },
    {
      width: 2,
      title: "Parent Client",
      field: "client_id_parent",
      type: "selectclient",
    },
  ];

  const id = props.itemId;

  useEffect(() => {
      dispatch(
        utilityActions.getUtility("client_soc_locations", {
          perPage: 1000,
          filters: [
            { field: "is_admin_only", operator: "eq", value: 1 },
          ],
        })
      );
    if (states.items.length === 0) {
      dispatch(
        utilityActions.getUtility("us_states", {
          perPage: 1000,
          orders: [
            {
              field: "stateabbreviation",
              direction: "asc",
            },
          ],
        })
      );
    }
    if (auctions.items.length === 0) {
      dispatch(
        utilityActions.getUtility("auctions", {
          perPage: 1000,
        })
      );
    }
    if (clientGroups.items.length === 0) {
      dispatch(
        utilityActions.getUtility("client_groups", {
          perPage: 1000,
        })
      );
    }
    if (clientTypes.items.length === 0) {
      dispatch(
        utilityActions.getUtility("client_types", {
          perPage: 1000,
        })
      );
    }
    if (paymentTypes.items.length === 0) {
      dispatch(
        utilityActions.getUtility("payment_types", {
          perPage: 1000,
        })
      );
    }
    if (titleApplicationTypes.items.length === 0) {
      dispatch(
        utilityActions.getUtility("title_application_types", {
          perPage: 1000,
          orders: [
            {
              field: "titleapplicationtype",
              direction: "asc",
            },
          ],
        })
      );
    }
    if (title_statuses.items.length === 0) {
      dispatch(
        utilityActions.getUtility("title_statuses", {
          perPage: 1000,
        })
      );
    }
    if (clients_active.items.length === 0) {
      dispatch(
        utilityActions.getUtility("clients_active", {
          perPage: 1000,
          filters: [
            {
              field: "clientactive",
              operator: "eq",
              value: "2",
            },
          ],
          orders: [{ field: "clientname", direction: "asc" }],
        })
      );
    }
  }, []);

  useEffect(() => {
    if (id) {
      if (!item) {
        getNewData();
      } else if (Number(item.id) !== Number(id)) {
        getNewData();
      }
    }
  }, [id]);

  const getNewData = () => {
    // console.log("getNewData");
    dispatch(
      utilityActions.getOneUtility("clients", id, [], () => {
        setOpenBackdrop(false);
      })
    );
  };

  const updateLocal = (event) => {
    const { name, value } = event.target;
    // console.log("updateLocal", { name, value });
    dispatch(
      utilityActions.updateOneUtilityLocal("clients", { [name]: value })
    );
  };

  const handleSave = () => {
    setOpenBackdrop(true);
    dispatch(
      utilityActions.updateUtility("clients", item.id, item, () => {
        getNewData();
      })
    );
  };

  return (
    <>
      <Backdrop open={openBackdrop} style={{ zIndex: 10000, color: "white" }}>
        <CircularProgress />
        Updating
      </Backdrop>
      <div style={{ display: "inline", marginBottom: 10 }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </div>
      {item && item.id && item.is_ri && !item.ri_reg_docs_complete ? (
        <Alert severity="error" variant="filled">
          RI Client "{item.clientname}" has not completed registration documents
        </Alert>
      ) : (
        <></>
      )}
      <Hidden xsDown>
        <AppBar position="static" color="default">
          <Tabs
            value={tabIndex}
            onChange={(e, newValue) => setTabIndex(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <SmallTab label="Basic Details" />
            <SmallTab label="Notes" />
            <SmallTab label="Rack Rates" />
            <SmallTab label="Defaults" />
            <SmallTab label="GST/HST" />
          </Tabs>
        </AppBar>
      </Hidden>
      <Hidden smUp>
        <AppBar position="static" color="default" style={{ padding: 20 }}>
          <Select
            native
            value={tabIndex}
            onChange={(e) => setTabIndex(e.target.value)}
          >
            <option value={0}>Basic Details</option>
            <option value={1}>Notes</option>
            <option value={2}>Rack Rates</option>
            <option value={3}>Defaults</option>
            <option value={4}>GST/HST</option>
          </Select>
        </AppBar>
      </Hidden>
      <Typography
        component="div"
        role="tabpanel"
        hidden={tabIndex !== 0}
        style={{ paddingTop: 10 }}
      >
        {item && item.id ? (
          <Grid container spacing={1}>
            {fields.map((field, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={4}>
                  {field.title}
                </Grid>
                <Grid item xs={12} sm={8}>
                  {field.type === "selectclientsoclocation" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field] || ""}
                      onChange={updateLocal}
                      style={{ fontSize: 16 }}
                      displayEmpty
                    >
                      <option value="">Choose Address</option>
                      {clientSocLocations &&
                        clientSocLocations.items.map((location) => (
                          <option key={String(location.id)} value={location.id}>
                            {location.alias}
                          </option>
                        ))}
                    </Select>
                  )}
                  {field.type === "string" && (
                    <TextField
                      name={field.field}
                      placeholder={field.title}
                      value={item[field.field] || ""}
                      style={{ width: "100%" }}
                      onChange={updateLocal}
                    />
                  )}
                  {field.type === "state" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field] || 1}
                      onChange={updateLocal}
                      style={{ fontSize: 16 }}
                      displayEmpty
                    >
                      <option value="">State</option>
                      {states &&
                        states.items.map((state, index) => (
                          <option key={index} value={state.stateid}>
                            {state.stateabbreviation} - {state.statefull}
                          </option>
                        ))}
                    </Select>
                  )}
                  {field.type === "country" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field] || ""}
                      onChange={updateLocal}
                      style={{ fontSize: 12 }}
                      displayEmpty
                    >
                      <option value="">Country</option>
                      <option value={1}>United States</option>
                      <option value={2}>Canada</option>
                    </Select>
                  )}
                  {field.type === "selectcountrycode" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field] || ""}
                      onChange={updateLocal}
                      style={{ fontSize: 16 }}
                    >
                      <option value="US">United States</option>
                      <option value="CA">Canada</option>
                      <option value="MX">Mexico</option>
                    </Select>
                  )}
                  {field.type === "role" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field] || ""}
                      onChange={updateLocal}
                      style={{ fontSize: 12 }}
                      displayEmpty
                    >
                      <option value="">Role</option>
                      <option value={2}>Admin</option>
                      <option value={1}>Client</option>
                      <option value={11}>Client Admin</option>
                    </Select>
                  )}
                  {field.type === "selectclientgroup" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field] || ""}
                      onChange={updateLocal}
                      style={{ fontSize: 12 }}
                      displayEmpty
                    >
                      <option value="">No Group</option>
                      {clientGroups &&
                        clientGroups.items.map((group, index) => (
                          <option key={index} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                    </Select>
                  )}
                  {field.type === "selectclienttype" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field] || ""}
                      onChange={updateLocal}
                      style={{ fontSize: 12 }}
                      displayEmpty
                    >
                      <option value="">Client Type</option>
                      {clientTypes &&
                        clientTypes.items.map((clientType, index) => (
                          <option key={index} value={clientType.id}>
                            {clientType.clienttype}
                          </option>
                        ))}
                    </Select>
                  )}
                  {field.type === "selectpaymenttype" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field] || ""}
                      onChange={updateLocal}
                      style={{ fontSize: 12 }}
                      displayEmpty
                    >
                      <option value="">Client Type</option>
                      {paymentTypes &&
                        paymentTypes.items.map((paymentType, index) => (
                          <option key={index} value={paymentType.id}>
                            {paymentType.paymenttype}
                          </option>
                        ))}
                    </Select>
                  )}
                  {field.type === "selectclient" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field] || ""}
                      onChange={updateLocal}
                      style={{ fontSize: 12 }}
                      displayEmpty
                    >
                      <option key={index} value="">
                        Select Client
                      </option>
                      {clients_active &&
                        clients_active.items.map((client, index) => (
                          <option key={index} value={client.clientid}>
                            {client.clientname}
                          </option>
                        ))}
                    </Select>
                  )}
                  {field.type === "boolean" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field]}
                      onChange={updateLocal}
                      style={{ fontSize: 12 }}
                    >
                      <option value={0}>No</option>
                      <option value={1}>Yes</option>
                    </Select>
                  )}

                  {field.type === "selectrackrates" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field]}
                      onChange={updateLocal}
                      style={{ fontSize: 12 }}
                    >
                      <option value={0}>No</option>
                      <option value={1}>Yes</option>
                    </Select>
                  )}
                  {field.type === "selectclientactive" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field] || ""}
                      onChange={updateLocal}
                      style={{ fontSize: 12 }}
                    >
                      <option value={1}>No</option>
                      <option value={2}>Yes</option>
                    </Select>
                  )}
                  {field.type === "selectsingleinvoice" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field] || 2}
                      onChange={updateLocal}
                      style={{ fontSize: 12 }}
                    >
                      <option value={1}>Yes</option>
                      <option value={2}>No</option>
                    </Select>
                  )}
                  {field.type === "selectspecialmailing" && (
                    <Select
                      native
                      name={field.field}
                      value={item[field.field] || 1}
                      onChange={updateLocal}
                      style={{ fontSize: 12 }}
                    >
                      <option value={1}>No</option>
                      <option value={2}>Yes</option>
                    </Select>
                  )}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </Typography>
      <Typography
        component="div"
        role="tabpanel"
        hidden={tabIndex !== 1}
        style={{ paddingTop: 10 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            Notes
          </Grid>
          <Grid item xs={12}>
            {item && item.id ? (
              <ClientNotes clientId={props.itemId} />
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      </Typography>
      <Typography
        component="div"
        role="tabpanel"
        hidden={tabIndex !== 2}
        style={{ paddingTop: 10 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <RouterLink to="/admin/rackRates">Rack Rate Overrides</RouterLink>
          </Grid>
          <Grid item xs={12}>
            {item && item.id ? (
              <ClientOverrides clientId={props.itemId} />
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      </Typography>
      <Typography
        component="div"
        role="tabpanel"
        hidden={tabIndex !== 3}
        style={{ paddingTop: 10 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            Defaults
          </Grid>
          <Grid item xs={12}>
            {item && item.id ? (
              <ClientDefaults
                clientId={props.itemId}
                defaultsObject={item.defaults}
              />
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      </Typography>
      <Typography
        component="div"
        role="tabpanel"
        hidden={tabIndex !== 4}
        style={{ paddingTop: 10 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            GST/HST
          </Grid>
          <Grid item xs={12}>
            {item && item.id ? (
              <ClientGstHst clientId={props.itemId} />
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      </Typography>
    </>
  );
}

const SmallTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const styles = (theme) => ({
  root: {
    display: "flex",
    fontSize: 12,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
  },
  fixedHeight: {
    height: 240,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  Button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

function mapStateToProps(state) {
  const { authentication, utility, batch } = state;
  const { user } = authentication;
  return {
    user,
    batch,
    utility,
  };
}

const connectedAdminClientDetailsPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(AdminClientDetailsPage))
);
export { connectedAdminClientDetailsPage as AdminClientDetailsPage };
